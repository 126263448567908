<!-- 登录页面 -->
<template>
	<div style="">
		<div class="container">
			<div class="row pt-5">
				<div class="col-12 col-sm-9 col-md-7 col-lg-5 m-auto pt-5" >
					<div class="card mt-5" style="background-color: rgba(255,255,255)" v-if="dqxz==1">
						<div class="card-header">
							<h4 class="text-center mt-2" style="font-weight:800;background-color: unset;color: #1890ff;">注册</h4>
						</div>
						<div class="card-body">
							<el-form ref="ruleForm" :rules="rules" :model="form">
								<el-form-item prop="username" class="mt-4">
									<el-input v-model="form.username" size="medium" placeholder="请输入用户名"></el-input>
								</el-form-item>
								<el-form-item prop="password" class="my-4">
									<el-input v-model="form.password" size="medium" type="password" placeholder="请输入密码"></el-input>
								</el-form-item>
								<el-form-item prop="password" class="my-4">
									<el-input v-model="form.passworda" size="medium" type="password" placeholder="请再次输入密码"></el-input>
								</el-form-item>
								<el-form-item prop="password" class="my-2">
									<div class="d-flex" style="align-items: center;">
										<div class="login" @click="getAgreeShow()" :class="agreeShow ? 'tick' : ' '"></div>
										<div style="font-size: 12px;" class="xy">
											我已经仔细阅读并接受
											<span @click="opena">《用户协议》</span>
											<span @click="openb">《隐私权政策》</span>
										</div>
									</div>
								</el-form-item>
								<el-form-item class="my-2">
									<div style="width: 100%;" class="d-flex">
										<div style="width: 50%;" class="d-flex j-center">
											<el-button type="primary" size="medium" style="width: 80%" @click="zczh" :loading="loading">注册</el-button>
										</div>
										<div style="width: 50%;" class="d-flex j-center">
											<el-button size="medium" style="width: 80%" @click="fhdl" :loading="loading">返回</el-button>
										</div>
									</div>
								</el-form-item>
							</el-form>
						</div>
					</div>
					<div class="card mt-5" style="background-color: rgba(255,255,255)" v-else-if="dqxz==0">
						<div class="card-header">
							<h4 class="text-center mt-2" style="font-weight:800;background-color: unset;color: #1890ff;">用户登录</h4>
						</div>
						<div class="card-body">
							<el-form ref="ruleForm" :rules="rules" :model="form">
								<el-form-item prop="username" class="mt-4">
									<el-input v-model="form.username" size="medium" placeholder="请输入用户名"></el-input>
								</el-form-item>
								<el-form-item prop="password" class="my-4">
									<el-input v-model="form.password" size="medium" type="password" placeholder="请输入密码"></el-input>
								</el-form-item>
								<el-form-item class="my-2">
									<div style="width: 100%;" class="d-flex">
										<div style="width: 50%;" class="d-flex j-center">
											<el-button type="primary" size="medium" style="width: 80%" @click="submit" :loading="loading">{{ loading ? '登录中...' : '立即登录' }}</el-button>
										</div>
										<div style="width: 50%;" class="d-flex j-center">
											<el-button size="medium" style="width: 80%" @click="fhdl" :loading="loading">注册</el-button>
										</div>
									</div>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
			</div>
			
		</div>
		<div class="container" style="margin-top: 10px;">
			<div style="font-size: 12px;color: #fff;">版本：v1.0.6</div>
			<div style="font-size: 12px;color: #fff;">公告：没有账号的用户直接注册账号可以免费领取30次使用次数</div>
			<div style="font-size: 12px;color: #fff;">预告：下一版本更新提示词社区，上架ai绘画（midjourey）以及图片社区。感兴趣的可以前往<span style="color: #fff700;">《魔法画画书》</span>微信小程序体验</div>
			<div style="font-size: 12px;color: #fff;">联系码农：aiyouweiLYY</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'; // 引入vuex 拿到 全局 公共数据
export default {
	data() {
		return {
			loading: false,
			form: {
				username: '',
				password: '',
				passworda:''
			},
			rules: {
				username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
				password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
				passworda: [{ required: true, message: '请输入密码', trigger: 'blur' }]
			},
			dqxz: 0,
			agreeShow: false,
		};
	},
	// 生命周期
	computed: {
		...mapGetters(['adminIndex'])
	},
	methods: {
		submit() {
			this.$refs.ruleForm.validate(e => {
				if (!e) return;
				// 提交表单
				this.loading = true;
				this.axios.post('/admin/wz/logins', this.form)
					.then(res => {
						// this.$message('密码错误');
						console.log(res.config)
						// 存储到vuex
						let data = res.data.data;
						// 存储到本地存储
						this.$store.commit('login', data);
						
						// 成功提示
						this.$message('登录成功');
						this.loading = false;
						// 跳转后台首页
						this.$router.push({ name: 'index' });
					})
					.catch(err => {
						this.loading = false;
					});
			});
		},
		getAgreeShow() {
		    this.agreeShow = !this.agreeShow; // 控制点击
		},
		zczh(){
			if(this.agreeShow == false){
				this.$message('请勾选注册须知');
			}else if(this.form.username < 2){
				this.$message('请输入用户名');
			}else if(this.form.password.length < 6){
				this.$message('密码至少6位数');
			}else if(this.form.password != this.form.passworda){
				this.$message('两次密码输入不一致');
			}else{
				// this.loading = true;
				this.axios.post('/admin/wz/zczh',this.form)
					.then(res => {
						if(res.data.data == '用户名已经存在'){
							this.$message('用户名已经存在');
						}else{
							this.$message('注册成功');
							this.loading = false;
							this.dqxz = 0
						}
					});
			}
		},
		fhdl(){
			if(this.dqxz == 0){
				this.dqxz = 1
				this.form.username = ''
				this.form.password = ''
				this.form.passworda = ''
			}else{
				this.dqxz = 0
				this.form.username = ''
				this.form.password = ''
				this.form.passworda = ''
			}
		},
		opena() {
			let tt = '尊敬的用户： 欢迎使用（以下简称“本网站”）！在使用本网站之前，请仔细阅读本用户协议（以下简称“'
			let ta = '协议”）。本协议是用户（以下简称“您”或“用户”）与之间就使用本网站所达成的协尊敬的用户：欢迎使用（以下简称“本网站”）！在使用本网站之前，请仔细阅读本用户协议（以下简称“协议”）。本协议是用户（以下简称“您”或“用户”）与之间就使用本网站所达成的协议，包括本网站提供的各种服务。请注意，一旦您访问或使用本网站，即表示您同意接受本协议的全部条款和条件。一、账户注册1.1 在使用本网站之前，您需要注册一个账户。为注册账户，您需要提供真实、准确、完整的个人信息，并保证您所提供的信息是真实、准确、完整的。1.2 您必须对您的账户及密码保密，不得将账户及密码告知第三方，否则应承担由此产生的全部责任。如您发现有任何未经授权使用您的账户或密码的情况，应立即通知。1.3您必须'
			let tb = '对使用您的账户和密码进行的所有活动负责。不对因您未能遵守本协议及相关法律法规规定而导致的任何损失承担责任。二、服务内容2.1 提供的服务包括但不限于：人工智能技术、数据分析、数据挖掘、数据处理等。具体服务内容以本网站实际提供的服务为准。2.2 您同意不使用本网站提供的服务进行非法活动，包括但不限于利用本网站进行违法犯罪活动、侵犯他人合法权益等行为。如有违反，应承担相应的法律责任。三、知识产权3.1 本网站上所有的内容（包括但不限于文字、图片、音频、视频等）的知识产权均属于或相关权利人所有。未经或相关权利人书面许可，任何人不得复制、传播、转载、修改或使用本网站的任何内容。3.2 您使用本网站提供的服务时，应遵守相关法律法规，尊重他人的知识产权。四、免责声明'
			let tc = '4.1 不对以下情况导致的任何损失或责任承担责任：因不可抗力、计算机病毒、黑客攻击、系统不稳定等原因导致的本网站无法访问或服务中断、信息丢失或损毁、交易信息错误等问题。4.2 不对因您自身的原因导致的任何损失或责任'
			let title = tt+ta+tb+tc
			
			this.$alert('<strong><div style="height:380px;overflow-y:scroll;margin-left: 10px;font-weight: 400;">'+title+'</div></strong>', '用户协议', {
				dangerouslyUseHTMLString: true,
				callback: action => {

				}
			});
		},
		openb() {
			let ta = '尊敬的用户： 欢迎使用（以下简称“本网站”）！在使用本网站之前，请仔细阅读本隐私权政策（以下简称“本政策”）。本政策说明本网站如何收集、使用、披露、处理和保护用户信息。请注意，一旦您访问或使用本网站，即表示您同意接受本政策的全部条款和条件。 一、信息收集 1.1 当您访问或使用本网站时，我们可能会收集您的个人信息，包括但不限于您的姓名、电子邮件地址、电话号码、IP地址、设备信息等。我们收集这些信息是为了提供更好的服务和用户体验。'
			let tb = '1.2 我们可能会通过本网站或其他途径收集您的信息，包括但不限于通过Cookies、标记和其他技术收集的信息。 1.3 我们不会主动收集或处理与您的个人敏感信息相关的信息，例如您的种族、宗教信仰、政治倾向、健康状况、性取向等。如果您提供这些信息，我们将按照法律法规的要求对其进行保护。 二、信息使用 2.1 我们将使用您的信息为您提供服务，并改进和优化我们的服务。 2.2 我们可能将您的信息用于本网站或其他产品或服务的推广或市场营销目的。 2.3 我们不会将您的个人信息出售、出租或交易给第三方。 三、信息披露 3.1 我们可能会在以下情况下披露您的个人信息：'
			let tc = '（1）按照法律法规、法院命令、政府部门的要求或者其他法律程序的规定进行披露； （2）为保护我们的权利、财产或安全，以及我们的用户或公众的权利、财产或安全而必须披露的情况； （3）与我们的附属公司、合作伙伴和服务提供商共享您的信息，以便他们能够为您提供服务。 四、信息处理 4.1 我们将采取合理的安全措施保护您的个人信息，并防止未经授权的访问、使用或泄露。 4.2 我们将采取合理的技术手段收集、存储和处理您的个人信息，并将根据法律法规的要求对其进行保护。 五、未成年人信息保护 5.1 本网站适用于年满18岁或以上的成年人。我们不会有意向未满18岁的儿童收集他们的个人信息。如果我们得知您提供给我们的信息属于未成年人的个人信息，我们将立即采取合理措施删除该信息。 六、隐私权政策的更新和修订'
			let td = '6.1 我们可能会不定期地更新和修订本政策。在本政策发生重大变化时，我们将在本网站发布更新版本。在更新版本发布后，您继续访问或使用本网站，即视为您同意受更新版本的政策约束。 七、联系我们 7.1 如果您对本隐私权政策或我们处理您的个人信息的方式有任何疑问或意见，请联系我们。 联系邮箱： 八、其他 8.1 本政策适用于本网站的所有用户。如果您是本网站的注册用户，您还应遵守与注册服务相关的协议。 8.2 本政策中的标题仅供方便阅读，不影响政策本身的含义和解释。 8.3 本政策的解释、执行和纠纷解决均适用中华人民共和国的法律。如本政策与中华人民共和国的法律相抵触，以法律规定为准。 本隐私权政策最近更新日期为：2023年3月7日。'
			let title = ta+tb+tc+td
			
			this.$alert('<strong><div style="height:380px;overflow-y:scroll;margin-left: 10px;font-weight: 400;">'+title+'</div></strong>', '隐私权政策', {
				dangerouslyUseHTMLString: true,
				callback: action => {
			
				}
			});
		},

	}
};
</script>

<style>
	html {
		width:100vw;
		overflow-x:hidden;
	}
	body{
		background: url(http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/64dc7ea68427c.jpg) no-repeat;
		background-size:cover;
		background-attachment:fixed;
		padding-right: 0px !important;
	}
	.login {
	    position: relative;
	    width: 14px;
	    height: 14px;
	    margin: 0 6px;
	    border: 1px solid #e64848 !important;
	    border-radius: 2px;
	  }
	  .tick::after {
	      content: " ";
	      position: absolute;
	      display: inline-block;
	      width: 12px;
	      height: 6px;
	      border-width: 0 0 2px 2px;
	      overflow: hidden;
	      border-color: #e64848;
	      border-style: solid;
	      -webkit-transform: rotate(-50deg);
	      transform: rotate(-50deg);
	      left: 0px;
	      top: 2px;
	    }
	.xy span{
		color: #1890ff;
	}
	.a-center{
		align-items: center;
	}
	.j-center{
		justify-content: center;
	}
	.el-message-box{
		width: 322px;
	}
	::-webkit-scrollbar-track{
		-webkit-box-shadow: inset 0 0 0 rgba(0,0,0,0.1);
		background: #fff;
	}
	::-webkit-scrollbar-thumb{
		-webkit-box-shadow: inset 0 0 0 rgba(0,0,0,0.1);
		background: #fff;
	}
</style>
